<template>
  <div class="agreement">
    <div>
      <div>
        <ValidationObserver ref="formCreateAgreement">
          <form>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <label class="inline-flex items-center mt-3">
                  <input v-model="edit_agreement_number" :value="edit_agreement_number" type="checkbox" class="form-checkbox h-4 w-4" />
                  <span class="ml-2 text-sm font-sans font-semibold">Ändra avtalsnummer?</span>
                </label>
              </div>
              <div class="w-full md:w-1/3 px-2 py-2" v-if="edit_agreement_number">
                <label class="text-gray-700 text-sm font-bold pb-2">Avtalsnummer</label>
                <ValidationProvider name="Avtalsnummer" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <input-mask class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50" type="text" v-model="edited_agreement_number" mask="SE9999-9999"></input-mask>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="mx-2 border-b border-secondary-400 my-5"></div>

            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="e_bill" type="text" field_name="E-faktura" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full text-sm font-bold italic px-2 mb-4">
                Hyrestagare
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="agreement_type" :options="agreement_types" nameSelector="name" optionSelector="id" field_name="Avtalstyp" rules="required" @change="changeAgreementType()" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2" v-if="agreement_type == 'customer'">
                <BaseSelect v-model="customer_id" :options="customers" nameSelector="name" optionSelector="_id" field_name="Kund" @change="preFillCustomerAddress()" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2" v-if="agreement_type == 'customer' && customer_id != ''">
                <BaseSelect v-model="unit_id" :options="customer_units" nameSelector="name" optionSelector="_id" field_name="Verksamheter" @change="preFillUnitAddress()" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2" v-if="agreement_type == 'reseller'">
                <BaseSelect v-model="reseller_id" :options="resellers" nameSelector="name" optionSelector="_id" field_name="Återförsäljare" @change="preFillResellerAddress()" />
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="customer_name" type="text" field_name="Namn" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="company_number" type="text" field_name="Org. nummer" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="customer_phone" type="text" field_name="Telefon" rules="required" />
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="billing_adress" type="text" field_name="Fakturaadress" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="billing_zip_code" type="text" field_name="Postnummer" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="billing_city" type="text" field_name="Ort" rules="required" />
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="customer_contact" type="text" field_name="Kontaktperson" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="customer_reference" type="text" field_name="Ref" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full text-sm font-bold italic px-2 mb-4">
                Hyresgivare
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="dealer_name" type="text" field_name="Namn " readonly disabled />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="dealer_company_number" type="text" field_name="Org. nummer " readonly disabled />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="dealer_phone" type="text" field_name="Telefon " readonly disabled />
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="dealer_address" type="text" field_name="Adress " readonly disabled />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="dealer_zip_code" type="text" field_name="Postnummer " readonly disabled />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="dealer_city" type="text" field_name="Ort " readonly disabled />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="seller" :options="sellers" nameSelector="name" optionSelector="_id" field_name="Säljare" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/2 px-2 py-2">
                <BaseTextarea rows="5" v-model="units_specification" field_name="Objektspecifikation, tillverkningsår, extra utrustning mm" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <ValidationProvider name="Avtalsstart" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Avtalsstart<b class="text-red-500 ml-1">*</b></label>
                    <date-picker :lang="date_picker_lang" :key="date_picker_langKey" class="mt-2" v-model="contract_start" value-type="format" format="YYYY-MM-DD"></date-picker>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="contract_period" type="text" field_name="Avtalsperiod" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="sim_card" :options="sim_operators" field_name="Simkort" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="alarm_center" :options="[...alarm_centers, { _id: '-', name: 'Ingen' }]" nameSelector="name" optionSelector="_id" field_name="Larmcentral" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="payment_terms" type="text" field_name="Betalningsvillkor" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="setup_cost" type="text" field_name="Uppläggningsavgift" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="rental_month_cost" type="text" field_name="Hyreskostnad" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/2 px-2 py-2">
                <BaseTextarea rows="5" v-model="special_terms" field_name="Särskilda villkor" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>

            <div class="flex flex-wrap">
              <div class="w-full px-2">
                <div class="clearfix">
                  <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createAgreement()">
                    Spara
                    <BaseIcon icon="arrow-right" class="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  name: "CreateAgreement",
  title() {
    return `Skapa nytt avtal | SecurCloud`;
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      user: this.$store.state.user,
      sim_operators: ["Telia", "Telia DCP", "Telenor", "Tele2"],
      agreement_types: [
        { id: "customer", name: "Kunder" },
        { id: "reseller", name: "Återförsäljare" },
      ],
      customer_units: [],
      customers: [],
      units: [],
      sellers: [],
      resellers: [],
      alarm_centers: [],
      customer_id: "",
      unit_id: "",
      reseller_id: "",
      agreement_type: "customer",
      e_bill: "",
      customer_name: "",
      company_number: "",
      customer_phone: "",
      billing_adress: "",
      billing_zip_code: "",
      billing_city: "",
      customer_contact: "",
      customer_reference: "",
      dealer_name: "Securdia AB",
      dealer_company_number: "559270-2087",
      dealer_phone: "08-520 276 42",
      dealer_address: "Ekbacksvägen 22, 3tr",
      dealer_zip_code: "168 69",
      dealer_city: "Bromma",
      seller: "",
      units_specification: "",
      contract_start: "",
      contract_period: "",
      setup_cost: "",
      rental_month_cost: "",
      payment_terms: "Årsvis, Förskott",
      special_terms: "",
      sim_card: "",
      alarm_center: "",
      edit_agreement_number: false,
      edited_agreement_number: "",
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
    };
  },

  methods: {
    getSellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/sellers`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/reseller`)
        .then((response) => {
          this.resellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer`)
        .then((response) => {
          this.customers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUnits() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/units`)
        .then((response) => {
          this.units = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getAlarmCenters() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarm_centers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    createAgreement() {
      this.$refs.formCreateAgreement.validate().then((success) => {
        if (!success) {
          return;
        }

        let data = {
          agreement_type: this.agreement_type,
          e_bill: this.e_bill,
          customer_name: this.customer_name,
          company_number: this.company_number,
          customer_phone: this.customer_phone,
          customer_contact: this.customer_contact,
          customer_reference: this.customer_reference,
          billing_adress: this.billing_adress,
          billing_zip_code: this.billing_zip_code,
          billing_city: this.billing_city,
          dealer_name: this.dealer_name,
          dealer_company_number: this.dealer_company_number,
          dealer_phone: this.dealer_phone,
          dealer_address: this.dealer_address,
          dealer_zip_code: this.dealer_zip_code,
          dealer_city: this.dealer_city,
          seller: this.seller,
          units_specification: this.units_specification,
          contract_start: this.contract_start,
          contract_period: this.contract_period,
          setup_cost: this.setup_cost,
          rental_month_cost: this.rental_month_cost,
          payment_terms: this.payment_terms,
          special_terms: this.special_terms,
          sim_card: this.sim_card,
          alarm_center: this.alarm_center,
          edit_agreement_number: this.edit_agreement_number,
          edited_agreement_number: this.edited_agreement_number,
        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/agreements`, data)
          .then((response) => {
            if (response.data.success) {
              if (this.user.role == "admin") this.$router.push("/admin/avtal");
              else if (this.user.role == "seller") this.$router.push("/mina-avtal/seller/" + this.user.seller_id);
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    changeAgreementType() {
      this.customer_id = "";
      this.unit_id = "";
      this.reseller_id = "";
      this.resetAddress();
    },

    preFillCustomerAddress() {
      let customer = _.find(this.customers, { _id: this.customer_id });
      if (customer) {
        this.resetAddress();

        this.customer_name = customer.name;
        this.company_number = customer.company_number;
        this.customer_phone = customer.phone;
        this.billing_adress = customer.billing_address;
        this.billing_zip_code = customer.billing_zip_code;
        this.billing_city = customer.billing_city;
        this.customer_contact = customer.contact_name;
        this.customer_reference = customer.billing_reference;
        this.e_bill = customer.billing_invoice;

        this.filterCustomerUnits();
      }
    },

    preFillUnitAddress() {
      let unit = _.find(this.units, { _id: this.unit_id });
      if (unit) {
        this.resetAddress(false);

        this.company_number = unit.company_number;
        this.customer_phone = unit.contact_phone;
        this.billing_adress = unit.billing_address;
        this.billing_zip_code = unit.billing_zip_code;
        this.billing_city = unit.billing_city;
        this.customer_contact = unit.contact_name;
        this.customer_reference = unit.billing_reference;
        this.e_bill = unit.billing_invoice;
      }
    },

    preFillResellerAddress() {
      let reseller = _.find(this.resellers, { _id: this.reseller_id });
      if (reseller) {
        this.resetAddress();

        this.customer_name = reseller.name;
        this.company_number = reseller.company_number;
        this.customer_phone = reseller.phone;
        this.billing_adress = reseller.address;
        this.billing_zip_code = reseller.zip_code;
        this.billing_city = reseller.city;
        this.customer_contact = reseller.contact_person;
        this.customer_reference = reseller.reference;
        this.e_bill = reseller.invoice;
      }
    },

    resetAddress(resetName = true) {
      if (resetName) this.customer_name = "";
      this.company_number = "";
      this.customer_phone = "";
      this.billing_adress = "";
      this.billing_zip_code = "";
      this.billing_city = "";
      this.customer_contact = "";
      this.customer_reference = "";
      this.e_bill = "";
    },

    filterCustomerUnits() {
      if (this.customer_id) {
        this.unit_id = "";
        let units = _.filter(this.units, { parent_id: this.customer_id });
        this.customer_units = units;
      }
    },
  },
  watch: {
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },
  created() {
    this.getCustomers();
    this.getUnits();
    this.getResellers();
    this.getSellers();
    this.getAlarmCenters();

    this.setPageTitle(`Skapa nytt avtal`, "create_agreement");
  },
};
</script>
